<template>
	<v-container v-if="!this.$store.getters.is_authenticated">{{ $t("system.login_required") }}</v-container>
	<v-container fluid v-else-if="test.uid == -1">{{ $t("education.loading_test") }}</v-container>
	<v-container fluid v-else>
		<v-container>
			<v-row>
				<v-col>
					<v-card>
						<v-card-title>{{ test.label.toUpperCase() }} ({{ test.note }})</v-card-title>
						<v-card-text>
							<v-list dense>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-row>
                                            <v-col>
                                                <v-list-item-title>{{ $t("education.ID_test") }}:</v-list-item-title>
												<v-list-item-subtitle>{{ test.testid }}</v-list-item-subtitle>
											</v-col>
										</v-row>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
                                    <v-list-item-content>
                                        <v-row>
                                            <v-col>
                                                <v-list-item-title>{{ $t("education.training") }}:</v-list-item-title>
												<v-list-item-subtitle>{{ get_date }}, {{ test.location }}</v-list-item-subtitle>
											</v-col>
										</v-row>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
                                    <v-list-item-content>
                                        <v-row>
                                            <v-col>
                                                <v-list-item-title>{{ $t("education.instructor") }}:</v-list-item-title>
												<v-list-item-subtitle>Peter Beragg</v-list-item-subtitle>
											</v-col>
										</v-row>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
                                    <v-list-item-content>
                                        <v-row>
                                            <v-col>
                                                <v-list-item-title>{{ $t("education.trainee") }}:</v-list-item-title>
												<v-list-item-subtitle>{{ test.name }} {{ test.surname }}</v-list-item-subtitle>
											</v-col>
										</v-row>
									</v-list-item-content>
								</v-list-item>
								<v-list-item>
                                    <v-list-item-content>
                                        <v-row>
                                            <v-col>
                                                <v-list-item-title>{{ $t("education.instructions_title") }}:</v-list-item-title>
												<v-list-item-subtitle class="jupi">{{ $t('education.instructions') }}</v-list-item-subtitle>
											</v-col>
										</v-row>
									</v-list-item-content>
								</v-list-item>
							</v-list>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<v-row v-for="(question, index) in test.questionList" :key=question+index>
				<v-col>
					<v-card >
						<v-card-title>{{ index + 1 }}. {{ question }}</v-card-title>
						<v-card-text>
							<v-checkbox hide-details v-for="(option, idx) in test.correctList[index]" :key=option+idx v-model="selected_answers[index][idx]" :label="option[1]"></v-checkbox>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<br>
			<v-btn block @click="submit_test" type="submit">{{ $t('education.btn_submit_test') }}</v-btn>

		</v-container>
		<br>
		<v-btn v-scroll="onScroll" v-show="fab" fab dark fixed bottom right color="primary" @click="toTop" :title="$t('education.scroll_to_top')">
			<v-icon>mdi-arrow-up-thick</v-icon>
        </v-btn>
	</v-container>
</template>

<script>
import ApiService from "@/services/api_service";

export default {


	data() {
		return {
			fab: false,
			test: {
				testid: -1,
				answerList: [],
				correctList: [],
				date: '',
				email: '',
				label: '',
				labelShort: '',
				location: '',
				name: '',
				note: '',
				questionList: [],
				result: [],
				statusTest: '',
				surname: '',
				uid: -1,
			},
			selected_answers: []
		};
	},
	methods: {
		onScroll (e) {
			if (typeof window === 'undefined') return
				const top = window.pageYOffset ||   e.target.scrollTop || 0
				this.fab = top > 20
		},
		toTop () {
			this.$vuetify.goTo(0)
		},
		submit_test() {
				ApiService.post('/education/submit/' + this.test.testid, {
					testid: this.test.tedstid,
					answerList: this.selected_answers
				})
				.then(response => {
					console.log(response)
					this.$router.push({ path: '/education/overview' })
				})
				.catch(() => {
				})
		}
	},
	computed: {
		get_date: function() {
			let dp = this.test.date.split('-')
			return dp[2] + '.' + dp[1] + '.' + dp[0]
		},
		kk: function() {
			return this.page
		},
	},
	mounted() {
		ApiService.get(`/education/test/` + this.$route.params.id).then((response) => {
			this.test = response.data;
			for(var i = 0; i < this.test.questionList.length; i++) {
				var mapicka = [];
				for(var j = 0; j < this.test.answerList[i].length; j++) {
					mapicka.push(false);
				}
				this.selected_answers.push(mapicka);
			}
			if(this.test.statusTest == 'pass') {
				this.selected_answers = response.data.answerList;
			}
		}).catch(() => {});
	},
};
</script>

<style scoped>
.v-card__title {
	word-break: break-word;
}
.answer_bad {
	text-color: red;
}
.answer_good {
	text-color: green;
}
.jupi {
	white-space: normal;
	text-align: justify;
}
</style>